import * as React from "react";
import Typography from "@mui/material/Typography";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Grid, CardActions } from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { useNavigate } from "react-router-dom";

import GroupsIcon from "@mui/icons-material/Groups";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import BrushIcon from '@mui/icons-material/Brush';
import theme from "../../Globals/theme";
function AppList() {
  let navigate = useNavigate();

  let BA = `${process.env.REACT_APP_BA_URL}`;
  let PM = `${process.env.REACT_APP_PM_URL}`;
  let CRM = `${process.env.REACT_APP_CRM_URL}`;
  let DEM = `${process.env.REACT_APP_DEM_URL}`;
  let FINMAN = `${process.env.REACT_APP_FINMAN_URL}`;
  let MARKETING = `${process.env.REACT_APP_MARKETING_URL}`;
  let LIBRARY = `${process.env.REACT_APP_LIBRARY_URL}`;

  const handleComingSoon = () => {
    navigate("/comingsoon");
  };

  const handleBA = () => {
    window.location.href = `${BA}`;
  };
  const handleDEM = () => {
    window.location.href = `${DEM}`;
  };

  const handleFinMan = () => {
    window.location.href = `${FINMAN}`;
  };

  const handlePM = () => {
    window.location.href = `${PM}`;
  };

  const handleCRM = () => {
    window.location.href = `${CRM}`;
  };

  const handleMARKETING = () => {
    window.location.href = `${MARKETING}`;
  };

  const handleLIBRARY = () => {
    window.location.href = `${LIBRARY}`;
  };

  return (
    <Grid 
    container 
    spacing={{ xs: 1, sm: 1, md: 1 }} 
    columns={{ xs: 2, sm: 4, md: 8 }} 
    direction="row" 
    justifyContent="center" 
    alignItems="center"
    >
      <Grid item align="center" sx={{ mb: 1 }}>
        <Card
        onClick={handleBA}
          sx={{
            maxWidth: 175,
            maxHeight: 200,
            minWidth: 150,
            minHeight: 300,
            display: "flex",
            justiyContent: "space-between",
            flexDirection: "column",
            '&:hover': {
              color: '#fff',
              background:'linear-gradient(45deg, #2196f3 30%, #6ab8f7 90%)',

              cursor: "pointer"
            },
          }}
          elevation={10}
        >
          <CardContent>
            <Typography align="center" variant="h6" sx={{ mb:-1.5 }}>
              Business Administration
            </Typography>
            <br />
            <GroupsIcon sx={{ fontSize: 40, mb:-2 }} />
            <Typography variant="body2">
              <br />
              Manage business administration, compliance and human resources.
            </Typography>
            <br />
          </CardContent>
          <CardActions>
      
          </CardActions>
        </Card>
      </Grid>
      <Grid item align="center" sx={{ mb: 1 }}>
        <Card
        onClick={handleFinMan}
          sx={{
            maxWidth: 175,
            maxHeight: 200,
            minWidth: 150,
            minHeight: 300,
            display: "flex",
            justiyContent: "space-between",
            flexDirection: "column",
            '&:hover': {
              color: '#fff',
              background:'linear-gradient(45deg, #2196f3 30%, #6ab8f7 90%)',

              cursor: "pointer"
            },
          }}
          elevation={10}
        >
          <CardContent>
            <Typography align="center" variant="h6" sx={{ mb:-1.5 }}>
              Financial Management
            </Typography>
            <br />
            <CurrencyExchangeIcon sx={{ fontSize: 40, mb:-2 }} />
            <Typography variant="body2">
              <br />
              Manage invoicing, payroll, accounting and financial statements.
            </Typography>
            <br />
          </CardContent>
          <CardActions>
 
          </CardActions>
        </Card>
      </Grid>
      <Grid item align="center" sx={{ mb: 1 }}>
        <Card
        onClick={handlePM}
          sx={{
            maxWidth: 175,
            maxHeight: 200,
            minWidth: 150,
            minHeight: 300,
            display: "flex",
            justiyContent: "space-between",
            flexDirection: "column",
            '&:hover': {
              color: '#fff',
              background:'linear-gradient(45deg, #2196f3 30%, #6ab8f7 90%)',

              cursor: "pointer"
            },
          }}
          elevation={10}
        >
          <CardContent>
            <Typography align="center" variant="h6" sx={{ mb:-1.5 }}>
              Project Management
            </Typography>
            <br />
            <AccountTreeIcon sx={{ fontSize: 40, mb:-2 }} />
            <Typography variant="body2">
              <br />
              Manage project profiles, planning and execution.
            </Typography>
            <br />
          </CardContent>
          <CardActions>
  
          </CardActions>
        </Card>
      </Grid>
      <Grid item align="center" sx={{ mb: 1 }}>
        <Card
        onClick={handleComingSoon}
          sx={{
            maxWidth: 175,
            maxHeight: 200,
            minWidth: 150,
            minHeight: 300,
            display: "flex",
            justiyContent: "space-between",
            flexDirection: "column",
            '&:hover': {
              color: '#fff',
              background:'linear-gradient(45deg, #2196f3 30%, #6ab8f7 90%)',

              cursor: "pointer"
            },
          }}
          elevation={10}
        >
          <CardContent>
            <Typography align="center" variant="h6" sx={{ mb:-1.5 }}>
              Procurement Management
            </Typography>
            <br />
            <WarehouseIcon sx={{ fontSize: 40, mb:-2 }} />
            <Typography variant="body2">
              <br />
              Manage service providers, suppliers and purchases.
            </Typography>
            <br />
          </CardContent>
          <CardActions>

          </CardActions>
        </Card>
      </Grid>
      <Grid item align="center" sx={{ mb: 1 }}>
        <Card
        onClick={handleCRM}
          sx={{
            maxWidth: 175,
            maxHeight: 200,
            minWidth: 150,
            minHeight: 300,
            display: "flex",
            justiyContent: "space-between",
            flexDirection: "column",
            '&:hover': {
              color: '#fff',
              background:'linear-gradient(45deg, #2196f3 30%, #6ab8f7 90%)',

              cursor: "pointer"
            },
          }}
          elevation={10}
        >
          <CardContent>
            <Typography align="center" variant="h6" sx={{ mb:-1.5 }}>
              Customer Relations
            </Typography>
            <br />
            <HandshakeIcon sx={{ fontSize: 40, mb:-2 }} />
            <Typography variant="body2">
              <br />
              Manage customer profiles, journeys and sales pipeline.
            </Typography>
            <br />
          </CardContent>
          <CardActions>

          </CardActions>
        </Card>
      </Grid>
      <Grid item align="center" sx={{ mb: 1 }}>
        <Card
        onClick={handleDEM}
          sx={{
            maxWidth: 175,
            maxHeight: 200,
            minWidth: 150,
            minHeight: 300,
            display: "flex",
            justiyContent: "space-between",
            flexDirection: "column",
            '&:hover': {
              color: '#fff',
              background:'linear-gradient(45deg, #2196f3 30%, #6ab8f7 90%)',

              cursor: "pointer"
            },
          }}
          elevation={10}
        >
          <CardContent>
            <Typography align="center" variant="h6" sx={{ mb:-1.5 }}>
              Digital Engineering
            </Typography>
            <br />
            <ConfirmationNumberIcon sx={{ fontSize: 40, mb:-2 }} />
            <Typography variant="body2">
              <br />
              Manage app development, operation and innovation.
            </Typography>
            <br />
          </CardContent>
          <CardActions>

          </CardActions>
        </Card>
      </Grid>
      <Grid item align="center" sx={{ mb: 1 }}>
        <Card
        onClick={handleMARKETING}
          sx={{
            maxWidth: 175,
            maxHeight: 200,
            minWidth: 150,
            minHeight: 300,
            display: "flex",
            justiyContent: "space-between",
            flexDirection: "column",
            '&:hover': {
              color: '#fff',
              background:'linear-gradient(45deg, #2196f3 30%, #6ab8f7 90%)',

              cursor: "pointer"
            },
          }}
          elevation={10}
        >
          <CardContent>
            <Typography align="center" variant="h6" sx={{ mb:-1.5 }}>
              Marketing Management
            </Typography>
            <br />
            <ThumbUpOffAltIcon sx={{ fontSize: 40, mb:-2 }} />
            <Typography variant="body2">
              <br />
              Manage marketing channels, strategy and analytics.
            </Typography>
            <br />
          </CardContent>
          <CardActions>

          </CardActions>
        </Card>
      </Grid>
      <Grid item align="center" sx={{ mb: 1 }}>
        <Card
        onClick={handleLIBRARY}
          sx={{
            maxWidth: 175,
            maxHeight: 200,
            minWidth: 150,
            minHeight: 300,
            display: "flex",
            justiyContent: "space-between",
            flexDirection: "column",
            '&:hover': {
              color: '#fff',
              background:'linear-gradient(45deg, #2196f3 30%, #6ab8f7 90%)',

              cursor: "pointer"
            },
          }}
          elevation={10}
        >
          <CardContent>
            <Typography align="center" variant="h6" sx={{ mb:-1.5 }}>
              Front-end Library
            </Typography>
            <br />
            <BrushIcon sx={{ fontSize: 40, mb:-2 }} />
            <Typography variant="body2">
              <br />
              Manage front-end component design and variations library.
            </Typography>
            <br />
          </CardContent>

        </Card>
      </Grid>
    </Grid>
  );
}

export default AppList;
