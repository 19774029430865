import { SET_SUCCESS, HIDE_SUCCESS } from "./successTypes"


export function setSuccess(success){
    return {
    type: SET_SUCCESS,
    success: success
    }
   }
   
   export function hideSuccess(){
    return {
    type: HIDE_SUCCESS
    }
   }
   