import React from 'react';
import { setSuccess, hideSuccess } from "../../state/actions"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
},);
const SuccessNotification = (props) => {

 function handleClose(){
 props.hideSuccess()
 }
 
 return (
 <>
    {props.isOpen && props.success && (
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
            open={props.isOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            TransitionComponent={Transition}
            onClose={handleClose}
            >
            <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
            >
            {props.success}
            </Alert>
            </Snackbar>
        </Stack>
    )}
 </>
 )
}

SuccessNotification.propTypes = {
    setSuccess: PropTypes.func.isRequired,
    hideSuccess: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    success: PropTypes.string,
  };
  
  const mapStateToProps = (state, ownProps) => {
    return { 
      isOpen: state.success.isOpen, 
      success: state.success.success,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setSuccess: () => dispatch(setSuccess()),
      hideSuccess: () => dispatch(hideSuccess()),
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(SuccessNotification);

