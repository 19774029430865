//React
import * as React from "react";
//Material UI
import AppBar from "@mui/material/AppBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector, useDispatch } from "react-redux";

//Routing
import { useNavigate } from "react-router-dom";
//Components
import custom_theme from "./theme";
import { logout } from "../../Features/Authentication/authSlice";

export const ResponsiveAppBar = (props) => {
  const dispatch = useDispatch();
  const [anchorElProfile, setanchorElProfile] = React.useState(null);
  const [anchorElLogout, setanchorElLogout] = React.useState(null);
  let navigate = useNavigate();

  const handlePrevPage = () => {
    navigate(-1);
  };

  const handleMenuLogout = (event) => {
    setanchorElLogout(event.currentTarget);
  };

  const handleCloseLogout = () => {
    setanchorElLogout(null);
  };

  const handleLogout = () => {
    setanchorElLogout(null);
    dispatch(logout());
  };

  const handleMenuProfile = (event) => {
    setanchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setanchorElProfile(null);
  };

  const handleProfile = () => {
    let BA = `${process.env.REACT_APP_BA_URL}`;
    window.location.href = `${BA}/profile`;
  };

  return (
    <Box sx={{ flexGrow: 1, mb: 10 }}>
      <AppBar style={custom_theme.appBarStyle.root}>
        <Toolbar>
          {/* Back Button */}
          <IconButton size="large" color="inherit" onClick={handlePrevPage}>
            <ArrowBackIcon />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 12 }} textAlign="center">
            BusiOpsMan
            <Typography variant="h6" component="div" sx={{ flexGrow: 12 }} textAlign="center">
              User Portal
            </Typography>
          </Typography>
          {/* profile button */}
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar-profile"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenuProfile}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar-right"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElProfile)}
              onClose={handleCloseProfile}
            >
              <MenuItem onClick={handleProfile}>Profile</MenuItem>
            </Menu>
          </div>
          {/* Logout button */}
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar-right"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenuLogout}
            >
              <LogoutIcon />
            </IconButton>
            <Menu
              id="menu-appbar-right"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElLogout)}
              onClose={handleCloseLogout}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
