import { createSlice, createAsyncThunk } from "@reduxjs/toolkit/";
import { clearCookie, getCookie, setCookie } from "../api/apiHelper";

const LOGIN_URL = `${process.env.REACT_APP_SECRET_API}/auth/login/`;

export const loginUser = createAsyncThunk("login/loginUser", async (body) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },

    body: JSON.stringify(body),
  };
  const response = await fetch(LOGIN_URL, requestOptions);

  return response.json();
});

const initialState = getCookie("access")
  ? {
      token: `Bearer ${getCookie("access")}`,
      isLoggedIn: true,
      isLoginError: false,
    }
  : { token: "", 
      isLoggedIn: false, 
      isLoginError: false 
    };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = "";
      clearCookie();
    },
  },
  extraReducers(builder) {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      let response = action.payload;
      if (response.access) {
        state.token = response.access;
        state.isLoggedIn = true;
        setCookie(response);
        state.isLoginError = false;
      } else {
        state.isLoginError = true;
      }
    });
  },
});

export const getToken = (state) => state.auth.token;
export const checkIsLoggedIn = (state) => state.auth.isLoggedIn;
export const checkIsLoginError = (state) => state.auth.isLoginError;

export const { logout } = authSlice.actions;

export default authSlice.reducer;
