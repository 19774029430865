import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// import { store } from "../state/store";
import { store } from "../store";
import theme from "../Globals/theme";
import { ThemeProvider } from "@mui/material/styles";
import MainRouter from "../Globals/routing/MainRouter";
function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MainRouter />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
