import { blue, green, grey, orange, pink, purple, red, black } from "@mui/material/colors";

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {

    primary: {
      main: blue[500],
      contrastText: "#fff",
    },

    information: {
      main: blue[500],
    },

    secondary: {
      main: red[500],
    },

    error: {
      main: red[500],
    },

    warning: {
      main: orange[500],
      contrastText: "#fff",
    },

    success: {
      main: green[500],
      contrastText: "#fff",
    },

    cancel_button: {
      main: grey[500],
      contrastText: "#fff",
    },

    filter_modal_form_control: {
      main: grey[600],
    },

    button_page_link: {
      main: { textDecorationLine: "none", color: "inherit" },
    },
  },

  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  logInStyle: {
    appImage: {
      width: "100%",
      height: "280px",
      objectFit: "contain",
    },
    card: {
      minHeight: "100vh",
      backgroundColor: blue[200],
    },
  },

  // https://stackoverflow.com/questions/69751510/how-to-have-different-color-for-alternate-row-for-mui-v4-table
  tableStyle: {
    primaryTable_th: {
      fontSize: 13,
      height: 40,
      textAlign: "center",
      color: "#ffff",
      width: 1000,
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      backgroundColor: blue[500],
    },

    primaryTable_td: {
      fontSize: 13,
      height: 40,
      textAlign: "center",
    },
    secondaryTable_td: {
      fontSize: 13,
      height: 40,
      textAlign: "left",
    },

    primaryTable_table: {
      border: "none",
      borderRadius: "4px",
      borderCollapse: "collapse",
      // Overflow hidden solved a major table corner rounding issue:
      overflow: "hidden",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },

    secondaryTable_table: {
      border: "none",
      borderRadius: "4px",
      borderCollapse: "collapse",
      // Overflow hidden solved a major table corner rounding issue:
      overflow: "hidden",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },

    overFlow: {
      maxWidth: '100%',
      overflowX: 'auto',
    },
  },
  
  navBarStyle: {
    menu_item_icon: {
      backgroundColor: "transparent",
    },
  },

  bottomNavStyle: {
    floatingActionStyle: {
      margin: "0px",
      top: "auto",
      right: "20px",
      bottom: "20px",
      left: "auto",
      position: "fixed",
    },
    toolTipStyle: {
      maxWidth: "none",
      whiteSpace: "nowrap",
    },
    toolTipStyle: {
      maxWidth: "620px",
    },
  },

  bottomQRNavStyle: {
    floatingActionStyle: {
      margin: "0px",
      top: "auto",
      right: "80px",
      bottom: "20px",
      left: "auto",
      position: "fixed",
    },
    toolTipStyle: {
      maxWidth: "none",
      whiteSpace: "nowrap",
    },
    toolTipStyle:{
      maxWidth : '620px',
    }
  },

  headerStyle: {
    fontFamily: ["helvetica", "serif"].join(","),
    color: "#424242",
    fontSize: 24,
    textAlign: "center",
    textShadow: "6px 6px 6px #c9c6c6",
    display: "block",
    fontWeight: "bolder",
    paddingBottom: "5px",
  },
  tableHeadingStyle: {
    fontFamily: ["helvetica", "serif"].join(","),
    color: "#424242",
    fontSize: 20,
    textAlign: "center",
    textShadow: "6px 6px 6px #c9c6c6",
    display: "block",
    fontWeight: "bolder",
    paddingBottom: "3px",
  },
  tableBarStyle: {
    root: {
      background_date: 'linear-gradient(45deg, #2196F3 30%, #219BF3 90%)',
      background_description: 'linear-gradient(45deg, #219BF3 30%, #21A0F3 90%)',
      background_app: 'linear-gradient(45deg, #21A0F3 30%, #21A4F3 90%)',
      background_priority: 'linear-gradient(45deg, #21A4F3 30%, #21A9F3 90%)',
      background_status: 'linear-gradient(45deg, #21A9F3 30%, #21AEF3 90%)',
      background_lead_dev: 'linear-gradient(45deg, #21AEF3 30%, #21B3F3 90%)',
      background_release: 'linear-gradient(45deg, #21B3F3 30%, #21B8F3 90%)',
      background_type: 'linear-gradient(45deg, #21B8F3 30%, #21BDF3 90%)',
      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
      color: 'white',
    },
  },
  buttonGroupStyle: {
    root: {
      display: 'flex'
    }
  },
  blueButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    color: 'white',
    }
  },
  disabledButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #6e797a 30%, #b5c6c9 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(188, 219, 224, .3)',
    color: 'white',
    }
  },
  warningButtonStyle: {
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      }
  },
  filterButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(18, 204, 52, .3)',
    // color: '#c5dfe8',
    }
  },
  filterModalButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    }
  },
  cancelButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: '#E54c38',
    border: 0,
    borderRadius: 3,
    color: 'white',
    }
  },
  updateButtonStyle: {
    root: {
    fontFamily: ["helvetica", "serif"].join(","),
    background: '#77DD77',
    border: 0,
    borderRadius: 3,
    color: 'white',
    }
  },
  filterCheckboxStyle: {
    root: {
    color: '#FF8E53',
    }
  },
  cardStyle: {
      root: {
        background: 'linear-gradient(45deg, #b9d6ed 30%, #ebeced 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(185, 214, 237, .3)',
        color: 'white',
    }
  },
  modalStyle: {
    root: {
      backdropFilter: 'blur(2px)',
    }
  },
  modalCardStyle: {
    root: {
      borderRadius: '8px',
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgba(255,255,255,0.5)',
      boxShadow: '0 1px 12px rgba(0, 0, 0, 0.25)',
      border: '1px solid rgba(255, 255, 255, 0.3)'
    }
  },
  appBarStyle: {
    root: {
      background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
      color: 'white',
    },
  },
  mainHeading: {
    root: {
      fontFamily: ["helvetica", "serif"].join(","),
      fontSize: 30,
      color: "black",
      textAlign: "center",
      'textShadow': "1px 1px 2px #7694b3",
    },
  },
  subHeading: {
    root: {
      fontFamily: ["helvetica", "serif"].join(","),
      fontSize: 20,
      color: "#4c5054",
      textAlign: "center",
      paddingTop: "10px",
      'textShadow': "1px 1px 2px #7694b3",
    },
  },
  stepper: {
    root: {
      "& .MuiStepIcon-active": { color: "red" },
      "& .MuiStepIcon-completed": { color: "green" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
    }
  },

});

export default theme;
