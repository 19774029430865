import { Routes, Route } from "react-router-dom";

import React from "react";

import AppList from "../../Features/AppList/AppListCT";
import LoginPage from "../../Features/Authentication/LoginPage";
import PrivateRoute from "./PrivateRoute";
import { ResponsiveAppBar } from "../../Globals/components/ResponsiveAppBar";

import ErrorNotification from "../components/ErrorNotification";
import SuccessNotification from "../components/SuccessNotification";
import DEMButton from "../components/DEMButton";
import ComingSoon from "../../Features/AppList/ComingSoon";
import { useSelector, useDispatch } from "react-redux";
import { checkIsLoggedIn } from "../../Features/Authentication/authSlice";

export default function MainRouter(props) {
  const isLoggedIn = useSelector(checkIsLoggedIn);
  return (
    <>
      {/* Components to load if user is authenticated */}
      {isLoggedIn ? (
        <>
          <ResponsiveAppBar />
          {/* <SuccessNotification /> */}
          {/* <ErrorNotification /> */}
          <DEMButton />
        </>
      ) : (
        <></>
      )}

      <Routes>
        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          <Route exact path="/" element={<AppList />} />
        </Route>

        {/* Public Routes */}
        <Route exact path="/login" element={<LoginPage />}></Route>
        <Route exact path="/comingsoon" element={<ComingSoon />}></Route>
      </Routes>
    </>
  );
}
