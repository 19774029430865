import { react, useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import AttractionsIcon from '@mui/icons-material/Attractions';
import { useSelector, useDispatch } from "react-redux";

import theme from "./theme";
import { useGetQuestionsQuery } from "../../Features/api/apiSlice";

export default function DEMButton(props) {
  const dispatch = useDispatch();

  const app = "userport";
  const url = window.location.href;

  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [skip, setSkip] = useState(true);
  const [request, setRequest] = useState("");

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const body = {
      ticket_description: description,
      ticket_app: app,
      ticket_type: type,
      ticket_questions: props.answers,
      ticket_url: url,
    };
    // dispatch(postAnswers(props.auth, body));
    setOpen(false);
    setType("");
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setType("");
  };

  const {
    data: questions,
    isSuccess,
    error,
    isLoading,
  } = useGetQuestionsQuery(request, {
    skip,
  });
  console.log(questions);
  const handleType = (event) => {
    setType(event.target.value);
    setRequest({ ticket_type: event.target.value, app: app });
    setSkip(false);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {}, [type]);

  const handleClientInputChange = (event, index) => {
    props.answers[index]["answer"] = event.target.value;
  };

  let mappedQuestions;

  if (isSuccess) {
    mappedQuestions = questions.data.map((question, index) => {
      return (
        <div key={question.id}>
          <Typography sx={{ mt: 4 }} variant="h6">
            {question.question}
          </Typography>
          <TextField required fullWidth multiline maxRows={Infinity} onChange={(event) => handleClientInputChange(event, index)} />
        </div>
      );
    });
  } else if (isLoading) {
    mappedQuestions = <p>Loading...</p>;
  }

  return (
    <div>
      {/* DEM Button */}
      <Grid sx={{ display: "flex", flexDirection: "row-reverse", position: "fixed" }} style={{ bottom: 10, right: 10 }}>
        <Fab onClick={handleClickOpen} color="primary" aria-label="add" size="medium">
          <AttractionsIcon sx={{ fontSize: 25 }} />
        </Fab>
      </Grid>

      {/* Modal */}
      <Dialog component="form" onSubmit={handleOnSubmit} open={open} onClose={handleClose}>
        <DialogTitle variant="h5">Bug or Improvement Request Form</DialogTitle>
        <DialogContent>
          <DialogContentText variant="body3">
            Please fill out the below form to make an improvement request for the application or log any bugs you have found.
          </DialogContentText>
          <br />
          <Typography sx={{ mb: 1 }} variant="h6">
            Bug or Improvement?
          </Typography>
          <FormControl required fullWidth>
            <InputLabel id="bug_improvement">Bug or Improvement</InputLabel>
            <Select onChange={handleType} labelId="bug_improvement_select" id="bug_improvement_select" defaultValue="" label="Bug or Improvement">
              <MenuItem key="Bug" value="BUG">
                Bug
              </MenuItem>
              <MenuItem key="Improvement" value="IMP">
                Improvement
              </MenuItem>
            </Select>
          </FormControl>
          <br />
          {type === "IMP" ? (
            <>
              <Typography sx={{ mt: 4 }} variant="h6">
                Please describe the improvement
              </Typography>
              <TextField onChange={handleDescription} required fullWidth multiline maxRows={Infinity} />
              {mappedQuestions}
              <br />
            </>
          ) : (
            <></>
          )}
          {type === "BUG" ? (
            <>
              <Typography sx={{ mt: 4 }} variant="h6">
                Please describe the bug
              </Typography>
              <TextField onChange={handleDescription} required fullWidth multiline maxRows={Infinity} />
              {mappedQuestions}
              <br />
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" style={theme.blueButtonStyle.root}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
