import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./Features/Authentication/authSlice";
import { apiSlice } from "./Features/api/apiSlice";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authenticationReducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});
