import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "./apiHelper";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SECRET_API}`,
    prepareHeaders: (headers) => {
      const token = getToken();
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ["dem_questions"],
  endpoints: (builder) => ({
    getQuestions: builder.query({
      query: (request) => `/gateway/quality_control/questions/?app=${request.app}&ticket_type=${request.ticket_type}`,
      providesTags: ["dem_questions"],
    }),

    get: builder.mutation({
      query: (request) => ({
        url: "/clients/",
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["clients"],
    }),
  }),
});

export const { useGetQuestionsQuery } = apiSlice;
