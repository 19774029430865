import * as React from "react";

import AppPhoto from "../../Globals/components/Images/LOGO.jpeg";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid } from "@mui/material";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import theme from "../../Globals/theme";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { checkIsLoggedIn, checkIsLoginError, loginUser } from "./authSlice";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as SvgFile } from '../../Globals/components/Images/home.svg'
import { Helmet } from 'react-helmet';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../../styles.css"
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit">Powered by Cauchy Consult</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function LogIn(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedin = useSelector(checkIsLoggedIn);
  const isLoginError = useSelector(checkIsLoginError)
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get("email");
    let password = data.get("password");

    dispatch(loginUser({ email, password }));
    // navigate("/");
  };

  useEffect(() => {
    if (isLoggedin) {
      navigate("/");
    }
  }, [isLoggedin, isLoginError]);

  return (
    <>
    <div className="center-login">
    <Helmet>
    <style>{'body { background: linear-gradient(45deg, #a3d4fa 30%, #eef7fe 90%); }'}</style>

  </Helmet>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Card
sx={{ minHeight: '250px' }}
        >
            <CardContent>
          <SvgFile/>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                  <TextField margin="normal" className="inputRounded" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    className="inputRounded"
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <Collapse in={isLoginError}>
                    <Alert action={<IconButton aria-label="close" color="inherit" size="small"></IconButton>} sx={{ mb: 2 }} severity="error">
                      Invalid email or password!
                    </Alert>
                  </Collapse>
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, borderRadius: 20 }}>
                    Sign In
                  </Button>
                </Box>
              </Box>
              <Copyright sx={{ mt: 1, mb: 0 }} />
              </CardContent>
        </Card>
            </Container>
    
        </ThemeProvider>
        </div>
  </>
  );
}
